import React from "react";
import lax from "lax.js";
import api from "../../services/api";

class About extends React.Component {
  state = {
    about: {},
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  componentDidMount() {
    const getAbout = async () => {
      const res = await api.get("/database/news__c", {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY + ":" + process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });

      if (res && res.data && res.data[0]) this.setState({ about: res.data[0] });
    };

    getAbout();
  }

  render() {
    return (
      <section id="about" className="about-area ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            {!this.state.about.onlyImageOne && <>
            <div className="col-lg-6">
              <div className="about-content">
                {/* <span>{this.state.about.introduction}</span> */}
                {/* <h2>
                  Mergulhe nas melhores práticas de{" "}
                  <b>Gestão de TI e Inovação</b> - ITSM
                </h2> */}

                <span
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: this.state.about.title,
                  }}
                ></span>

                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.about.content,
                  }}
                ></div>

                {/* <p>
                  Run2biz Experience 2021 é um evento voltado aos líderes de
                  Tecnologia que desejam mergulhar nas melhores práticas de
                  Gestão de TI (ITSM) e Inovação, bem como apreender sobre usos
                  e aplicações da plataforma 4Biz Service Management.
                </p> */}

                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p> */}

                {/* <div className="signature">
                  <img
                    src={require("../../assets/images/signature.png")}
                    alt="signature"
                  />
                </div>

                <Link to="#" className="btn btn-primary">
                  Read More
                  <i className="icofont-double-right"></i>
                </Link>

                <Link to="#" className="btn btn-secondary">
                  Buy Ticket
                </Link> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={this.state.about.image2}
                  className="about-img1"
                  alt="about"
                />

                <img
                  src={this.state.about.image1}
                  className="about-img2"
                  alt="about"
                />

                <img
                  src={require("../../assets/images/shapes/5.png")}
                  className="shape-img"
                  alt="about"
                />

                {/* <LaxButton buttonText="Explore More About" /> */}
              </div>
            </div>
            </>}

            {this.state.about.onlyImageOne && <>
              <div className="col-lg-12">
                <div className="about-content">
                  {/* <span>{this.state.about.introduction}</span> */}
                  {/* <h2>
                    Mergulhe nas melhores práticas de{" "}
                    <b>Gestão de TI e Inovação</b> - ITSM
                  </h2> */}

                  <span
                    className="mb-2"
                    dangerouslySetInnerHTML={{
                      __html: this.state.about.title,
                    }}
                  ></span>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.about.content,
                    }}
                  ></div>

                  {/* <p>
                    Run2biz Experience 2021 é um evento voltado aos líderes de
                    Tecnologia que desejam mergulhar nas melhores práticas de
                    Gestão de TI (ITSM) e Inovação, bem como apreender sobre usos
                    e aplicações da plataforma 4Biz Service Management.
                  </p> */}

                  {/* <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type
                    specimen book.
                  </p> */}

                  {/* <div className="signature">
                    <img
                      src={require("../../assets/images/signature.png")}
                      alt="signature"
                    />
                  </div>

                  <Link to="#" className="btn btn-primary">
                    Read More
                    <i className="icofont-double-right"></i>
                  </Link>

                  <Link to="#" className="btn btn-secondary">
                    Buy Ticket
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-12"><img src={this.state.about.image1} alt="" /></div>
              </>}
          </div>
        </div>
      </section>
    );
  }
}

export default About;
